body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  min-height: 100% !important;
}

.App {
  min-height: 100% !important;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#map {
  width: 100%;
  height: 400px;
  background-color: #eeeeee;
}

.markercluster-map {
  height: 90vh;
}

.text-sm {
  font-size: 0.9em;
}

.bar {
  margin-left: 10px;
  display: inline-block;
  height: 10px;
  background-color: #D7CCC8;
  border-radius: 5px;
}

.nowrap {
  white-space: nowrap;
}

.bg-registered {
  background-color: #EF6262;
}

.bg-collected {
  background-color: #F3AA60;
}

.bg-extracted {
  background-color: #468B97;
}

.bg-sequenced {
  background-color: #85A389;
}

.footer {
  background-color: #000000;
  color: #ffffff;
  width: 100%;
}

.logo {
  width: 250px;
  opacity: 1;
}

.close {
  font-size: 1.4em;
  cursor: pointer;
}

a, .a {
  text-decoration: none !important;
  color: #00aee3 !important;
  cursor: pointer;
}

.navbar-brand, .nav-link {
  color: rgba(0, 0, 0, 0.9) !important;
}

.badge-vu {
  background-color: #FAC241 !important;
  color: #ffffff;
}

.badge-en {
  background-color: #ff9900 !important;
  color: #ffffff;
}

.badge-cr {
  background-color: #cc3300 !important;
  color: #ffffff;
}

.badge-ew {
  background-color: #000000 !important;
  color: #ffffff;
}

.badge-ex {
  background-color: #000000 !important;
  color: #ff0000;
}

.badge-lc {
  background-color: #cccc00 !important;
  color: #ffffff;
}

.badge-dd {
  background-color: #eeeeee !important;
  color: #000000;
}

.badge-count {
  background-color: #eeeeee !important;
  color: #999999 !important;
}

.btn-confirm {
  border: 0px !important;
  background-color: #cccc00 !important;
  color: #000000 !important;
}

.btn-feedback, .btn-cancel {
  border: 0px !important;
  background-color: #FAC241 !important;
  color: #000000 !important;
}

.btn-light {
  border: 0px !important;
  background-color: #eeeeee !important;
}

.statistic .number {
  font-size: 2em;
  font-weight: bold;
}

.statistic .label {
  font-size: 0.9em;
}
